import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSanitizeInput]'
})
export class SanitizeInputDirective {
  private previousValue: string = '';

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    this.sanitizeInput();
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const key = event.key;
    if (!/^\d$/.test(key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    setTimeout(() => this.sanitizeInput(), 0);
  }

  private sanitizeInput() {
    const input = this.el.nativeElement;
    const currentValue = input.value;
    // only 0-9 allowed
    let sanitizedValue = currentValue.replace(/[^0-9]/g, '');
    if (sanitizedValue === '' && this.previousValue !== '') {
      sanitizedValue = '0';
    } else if (sanitizedValue !== '') {
      this.previousValue = sanitizedValue;
    }
    input.value = sanitizedValue;
    this.control.control?.setValue(Number(sanitizedValue));
  }
}
