import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'convertHMS'
})
export class ConvertHMSPipe implements PipeTransform {
    transform(value) {

        if (value == null || value == "" || value == undefined) {
            return "-";
        }

        var d = value;
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
    }
}