import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
 
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean { 
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo) {
      this.router.navigate(['/public'], { queryParams: { redirectUrl: state.url } });
      return false;
    }

    return true;
  }
}
