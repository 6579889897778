import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Helpers } from "../Heplers";
import { NotificationService } from "src/app/public/services/notification.service";
import { environment } from "src/environments/environment";

@Injectable()
export class Errorintercepoter implements HttpInterceptor {
  constructor(
    private help: Helpers,
    private notification: NotificationService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const isInternalApiRequest = req.url.startsWith(environment.apiUrl);
        if (err.status === 401) {
            if (isInternalApiRequest) {
                this.help.logout();
            }
        }

        if (err.status == 403) {
          this.notification.ErrorNotification(
            err.error.status,
            err.error.message
          );
          if (isInternalApiRequest) {
            this.help.logout();
          }
        }

        if (err.status == 500) {
          console.log(err);
        }
        
        if (err.status == 0) {
          this.notification.ErrorNotification(
            "Server Error",
            "Something Went wrong,please check after sometimes or contact administrator!"
          );
          // this.help.logout();
        }
        const error = err.error.message || err.statusText;
        console.log(err);
        return throwError(error);
      })
    );
  }
}