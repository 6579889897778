import { Helpers } from './../Heplers';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BasicAuth implements HttpInterceptor {
    constructor(private help: Helpers) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         
      
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      var token = userInfo == null ? null : userInfo.jwtToken;
     
      if (token) {
        req = req.clone({
         setHeaders: {
          Token: `Bearer ${token}`,

        
         }
       });

    }
      return next.handle(req);
    }
}
