import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/public/models/api-response.model';
import { environment } from 'src/environments/environment';
import { User, UserLog } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserServicesService {

  constructor(
    private http: HttpClient
  ) { }

  getAllUsers(): Observable<ApiResponse<User[]>>{
    return this.http.get<ApiResponse<User[]>>(environment.apiUrl+'Admin/GetAll-User');
  }

  createUser(user:User): Observable<ApiResponse<User>>{
    return this.http.post<ApiResponse<User>>(environment.apiUrl + 'Admin/Create_User?production='+ environment.production,user);
  }

  deleteUser(user:User): Observable<ApiResponse<User>>{
    return this.http.post<ApiResponse<User>>(environment.apiUrl + 'Admin/DeleteUser',user);
  }

  getUserById(id:any):Observable<ApiResponse<User>>{ 
    return this.http.get<ApiResponse<User>>(environment.apiUrl + 'Admin/Get_UserById?id='+id);
  }
  editUser(user:User): Observable<ApiResponse<User>>{
    return this.http.post<ApiResponse<User>>(environment.apiUrl + 'Admin/Update_User',user);
  }

  getAllUserLogs(): Observable<ApiResponse<UserLog[]>>{
    return this.http.get<ApiResponse<UserLog[]>>(environment.apiUrl+'Admin/GetAll-User-Logs');
  }

  getAllUserLogsByUserId(userid:number): Observable<ApiResponse<UserLog[]>>{
    return this.http.get<ApiResponse<UserLog[]>>(environment.apiUrl+'Admin/GetAllUserLogsByUserId?userid='+userid);
  }
  changeTheme(id:number,theme:number):Observable<ApiResponse<User>>{
    return this.http.get<ApiResponse<User>>(environment.apiUrl + 'Admin/ChangeTheme?id='+id+'&theme='+theme);
  }

}
