import { Component, OnInit, ElementRef } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { UserServicesService } from "src/app/admin/services/userServices/user-services.service";
import { ApiResponse } from "src/app/public/models/api-response.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HeaderVariablePopupComponent } from "../header-variable-popup/header-variable-popup.component";
import { environment } from "src/environments/environment";

export interface RouteInfo {
  path?: string;
  title: string;
  type: string;
  roleType: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}

export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

export const ROUTES: RouteInfo[] = [
  {
    path: "/admin/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "ni-shop text-primary",
    roleType: "Admin",
    isCollapsed: false
  },
  {
    path: "/admin/setting",
    title: "Settings",
    type: "link",
    icontype: "ni-shop text-primary",
    roleType: "Admin",
    isCollapsed: false
  },
  {
    path: "",
    title: "Variables",
    type: "modal",
    icontype: "ni-shop text-primary",
    roleType: "Admin",
    isCollapsed: false
  },
  {
    path: "https://duotax.sharepoint.com/:x:/r/sites/TheHub/_layouts/15/Doc.aspx?sourcedoc=%7B05222E8D-BAEA-4AC8-85CC-C2B27CE9E581%7D&file=Mastermind%20Defects.xlsx&action=default&mobileredirect=true&isSPOFile=1&wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&wdExp=TEAMS-TREATMENT&wdhostclicktime=1727153971896&web=1",
    title: "Feedback",
    type: "external",
    icontype: "ni-shop text-primary",
    roleType: "Admin",
    isCollapsed: false
  },
  {
    path: "/consultant/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "ni-shop text-primary",
    roleType: "Consultant"
  }
];

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  darkMode$ :string;
  public focus;
  public listTitles: any[];
  public location: Location;
  public userName:string;
  public userRole:string;
  public bgColor:string;
  public menuItems: any[];
  sidenavOpen: boolean = true;
  public profile:string;
  public userlog:string;
  public dashboardLink:string;
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private userService:UserServicesService,
    private modalService: NgbModal,
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
       if (event instanceof NavigationStart) {
           // Show loading indicator

       }
       if (event instanceof NavigationEnd) {
           // Hide loading indicator

           if (window.innerWidth < 1200) {
             document.body.classList.remove("g-sidenav-pinned");
             document.body.classList.add("g-sidenav-hidden");
             this.sidenavOpen = false;
           }
       }

       if (event instanceof NavigationError) {
           // Hide loading indicator

           // Present error to user
           console.log(event.error);
       }
   });

  }

  ngOnInit() {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userName = userInfo.firstName;
    this.userRole = userInfo.isAdminInd == 1 ? "Admin" : "Report Writer";
    // this.bgColor = userInfo.isAdminInd == 1 ? "nav-admin" : "nav-user";
    this.bgColor = environment.name == "Local" ? "nav-dev" : "nav-production";
    this.listTitles = ROUTES.filter(listTitle => listTitle);   
    this.menuItems = ROUTES.filter(menuItem => menuItem.roleType==this.userRole);
    this.profile = userInfo.isAdminInd == 1 ? "/admin/myProfile" : "/consultant/myProfile";
    this.userlog = userInfo.isAdminInd == 1 ? "/admin/userLog" : "/consultant/userLog";  
    this.dashboardLink = userInfo.isAdminInd == 1 ? "/admin" : "/consultant";
    this.darkMode$=userInfo.theme == 1 ? "dark-mode" : "light-mode" ;
  }
  checkTheme() {
    if(this.darkMode$ == "dark-mode") {
      document.body.classList.remove("light-mode");
      document.body.classList.add("dark-mode");    
      return true;
    }else {
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
      return false;
    }
  }
  onToggle(event): void {
    if (event.target.checked) {
    this.changeTheme(1);
    this.darkMode$ = "dark-mode";
    document.body.classList.remove("light-mode");
    document.body.classList.add("dark-mode");    
    }else {
      this.changeTheme(0);
      this.darkMode$ = "light-mode";
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    }
  }
  changeTheme(theme:number) {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    var id =Number(userInfo.id);
    this.userService.changeTheme(id,theme).subscribe((result:ApiResponse<any>) => {
      var res =  JSON.parse(localStorage.getItem("userInfo"));
      res.theme = result.data.theme;
      localStorage.setItem("userInfo",JSON.stringify(res));
    },(err:Error) => {

    })
  }
  logout() {
    localStorage.clear();
    document.body.classList.add("light-mode");
    document.body.classList.remove("dark-mode");
    this.router.navigateByUrl("/");  
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  openVariableModel() {    
    this.modalService.open(HeaderVariablePopupComponent, {
      windowClass: "modal modal-big  fade",
      backdrop: false,
      animation: true,
      size: "lg"
    })
  }
}
