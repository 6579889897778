import { Jobs } from "./job";

export class Opportunity{
    buildCost__c?: string;
    build_Date_OC_Date__c?: string;
    closeDate?: string;
    description?: string;
    first_Name_Owner_1__c?: string;
    first_Name_Owner_2__c?: string;
    first_Name_Owner_3__c?: string;
    first_Name_Owner_4__c?: string;
    first_Name_Owner_5__c?: string;
    id?: string;
    job_Number__c?: number;
    last_Name_Owner_1__c?: string;
    last_Name_Owner_2__c?: string;
    last_Name_Owner_3__c?: string;
    last_Name_Owner_4__c?: string;
    last_Name_Owner_5__c?: string;
    name?: string;
    property_Address_Street__c?: string;
    property_Address__c?: string;
    property_Address_State__c?:string
    property_Address_City__c?:string;
    property_Address_Postcode__c?:string;
    secondary_Address_Street__c?:string;
    secondary_Address_State__c?:string;
    secondary_Address_Postcode__c?:string;
    purchase_Date__c?: string;
    purchase_Price__c?: string;
    report_Start_Date__c?: string;
    settlement_Date__c?: string;
    date_available_for_rent__c?:string;
    sharePoint_Link__c?: string;
    rP_Data_Link__c?: string;
    xero_Invoice_Link__c?: string;
    stageName?: string;
    number_of_Days_from_Deadline_Date__c?:string;
    deadline_Date__c?: string;
    date_of_Enquiry__c?: string;
    type_of_Report__c?: string;
    dT_Company__c?: string;
    propertyType__c?: string;
    renovation_build_notes__c?: string;
    fillout_delegation__c?: string;
    floorAreaC?:string;
    jobs?: Jobs[];
    latestJob?: Jobs;
}
export class DashboardViewModel{
    opportunitiesCount?: number;
    opportunities?:Opportunity[];
}

export class TaxUsers{
    userNum?: number;
    firstName?: string;
    lastName?: string;
    titleName?: string;
    userEmai?: string;
    userPassword?: string;
    statusInd?: number;
    createdBy?: string;
    createdDt?: string;
}

 export class FinalizeReport
{
      id?: string;
      ownerId?: string; 
      isDeleted?: boolean;
      name?: string
      createdDate?: any;
      createdById?:any;
      lastModifiedDate?:any;
      lastModifiedById?: string;
      systemModstamp?: any; 
      lastActivityDate?: any;
      lastViewedDate?: any;
      lastReferencedDate?: any;
      staff__c?: string;
      div_43_Total__c?: number; 
      div_40_Total__c?: number; 
      total_Expenditure__c?: number;
      relatedOpportunity__c?: string;  

    //FY
      fY_Year_01__c?: string; 
      fY_Year_02__c?: string; 
      fY_Year_03__c?: string; 
      fY_Year_04__c?: string; 
      fY_Year_05__c?: string; 
      fY_Year_06__c?: string; 
      fY_Year_07__c?: string; 
      fY_Year_08__c?: string; 
      fY_Year_09__c?: string; 
      fY_Year_10__c?: string; 
      fY_Year_11__c?: string; 
      fY_Year_12__c?: string; 
      fY_Year_13__c?: string; 
      fY_Year_14__c?: string; 
      fY_Year_15__c?: string; 
      fY_Year_16__c?: string; 
      fY_Year_17__c?: string; 
      fY_Year_18__c?: string; 
      fY_Year_19__c?: string; 
      fY_Year_20__c?: string; 
      fY_Year_21__c?: string; 
      fY_Year_22__c?: string; 
      fY_Year_23__c?: string; 
      fY_Year_24__c?: string; 
      fY_Year_25__c?: string; 
      fY_Year_26__c?: string; 
      fY_Year_27__c?: string; 
      fY_Year_28__c?: string; 
      fY_Year_29__c?: string; 
      fY_Year_30__c?: string; 
      fY_Year_31__c?: string; 
      fY_Year_32__c?: string; 
      fY_Year_33__c?: string; 
      fY_Year_34__c?: string; 
      fY_Year_35__c?: string; 
      fY_Year_36__c?: string; 
      fY_Year_37__c?: string; 
      fY_Year_38__c?: string; 
      fY_Year_39__c?: string; 
      fY_Year_40__c?: string; 
      fY_Year_41__c?: string; 

    //D40
      div_40_Year_01__c?: number;
      div_40_Year_02__c?: number;
      div_40_Year_03__c?: number;
      div_40_Year_04__c?: number;
      div_40_Year_05__c?: number;
      div_40_Year_06__c?: number;
      div_40_Year_07__c?: number;
      div_40_Year_08__c?: number;
      div_40_Year_09__c?: number;
      div_40_Year_10__c?: number;
      div_40_Year_11__c?: number;
      div_40_Year_12__c?: number;
      div_40_Year_13__c?: number;
      div_40_Year_14__c?: number;
      div_40_Year_15__c?: number;
      div_40_Year_16__c?: number;
      div_40_Year_17__c?: number;
      div_40_Year_18__c?: number;
      div_40_Year_19__c?: number;
      div_40_Year_20__c?: number;
      div_40_Year_21__c?: number;
      div_40_Year_22__c?: number;
      div_40_Year_23__c?: number;
      div_40_Year_24__c?: number;
      div_40_Year_25__c?: number;
      div_40_Year_26__c?: number;
      div_40_Year_27__c?: number;
      div_40_Year_28__c?: number;
      div_40_Year_29__c?: number;
      div_40_Year_30__c?: number;
      div_40_Year_31__c?: number;
      div_40_Year_32__c?: number;
      div_40_Year_33__c?: number;
      div_40_Year_34__c?: number;
      div_40_Year_35__c?: number;
      div_40_Year_36__c?: number;
      div_40_Year_37__c?: number;
      div_40_Year_38__c?: number;
      div_40_Year_39__c?: number;
      div_40_Year_40__c?: number;
      div_40_Year_41__c?: number;
    //D43
       div_43_Year_01__c?: number;
       div_43_Year_02__c?: number;
       div_43_Year_03__c?: number;  
       div_43_Year_04__c?: number; 
       div_43_Year_05__c?: number; 
       div_43_Year_06__c?: number; 
       div_43_Year_07__c?: number; 
       div_43_Year_08__c?: number; 
       div_43_Year_09__c?: number;  
       div_43_Year_10__c?: number; 
       div_43_Year_11__c?: number; 
       div_43_Year_12__c?: number; 
       div_43_Year_13__c?: number; 
       div_43_Year_14__c?: number; 
       div_43_Year_15__c?: number;  
       div_43_Year_16__c?: number; 
       div_43_Year_17__c?: number; 
       div_43_Year_18__c?: number; 
       div_43_Year_19__c?: number; 
       div_43_Year_20__c?: number; 
       div_43_Year_21__c?: number; 
       div_43_Year_22__c?: number; 
       div_43_Year_23__c?: number; 
       div_43_Year_24__c?: number; 
       div_43_Year_25__c?: number;  
       div_43_Year_26__c?: number; 
       div_43_Year_27__c?: number; 
       div_43_Year_28__c?: number; 
       div_43_Year_29__c?: number; 
       div_43_Year_30__c?: number; 
       div_43_Year_31__c?: number; 
       div_43_Year_32__c?: number; 
       div_43_Year_33__c?: number; 
       div_43_Year_34__c?: number; 
       div_43_Year_35__c?: number;  
       div_43_Year_36__c?: number; 
       div_43_Year_37__c?: number; 
       div_43_Year_38__c?: number;
       div_43_Year_39__c?: number; 
       div_43_Year_40__c?: number; 
       div_43_Year_41__c?: number; 

    //Total
       total_Depreciation_Year_01__c?: number; 
       total_Depreciation_Year_02__c?: number; 
       total_Depreciation_Year_03__c?: number;  
       total_Depreciation_Year_04__c?: number; 
       total_Depreciation_Year_05__c?: number; 
       total_Depreciation_Year_06__c?: number; 
       total_Depreciation_Year_07__c?: number; 
       total_Depreciation_Year_08__c?: number; 
       total_Depreciation_Year_09__c?: number;  
       total_Depreciation_Year_10__c?: number; 
       total_Depreciation_Year_11__c?: number; 
       total_Depreciation_Year_12__c?: number; 
       total_Depreciation_Year_13__c?: number; 
       total_Depreciation_Year_14__c?: number; 
       total_Depreciation_Year_15__c?: number;  
       total_Depreciation_Year_16__c?: number; 
       total_Depreciation_Year_17__c?: number; 
       total_Depreciation_Year_18__c?: number; 
       total_Depreciation_Year_19__c?: number; 
       total_Depreciation_Year_20__c?: number; 
       total_Depreciation_Year_21__c?: number; 
       total_Depreciation_Year_22__c?: number; 
       total_Depreciation_Year_23__c?: number; 
       total_Depreciation_Year_24__c?: number; 
       total_Depreciation_Year_25__c?: number;  
       total_Depreciation_Year_26__c?: number; 
       total_Depreciation_Year_27__c?: number; 
       total_Depreciation_Year_28__c?: number; 
       total_Depreciation_Year_29__c?: number; 
       total_Depreciation_Year_30__c?: number; 
       total_Depreciation_Year_31__c?: number; 
       total_Depreciation_Year_32__c?: number; 
       total_Depreciation_Year_33__c?: number; 
       total_Depreciation_Year_34__c?: number; 
       total_Depreciation_Year_35__c?: number; 
       total_Depreciation_Year_36__c?: number; 
       total_Depreciation_Year_37__c?: number; 
       total_Depreciation_Year_38__c?: number; 
       total_Depreciation_Year_39__c?: number; 
       total_Depreciation_Year_40__c?: number; 
       total_Depreciation_Year_41__c?: number; 
}

export class SuccessResponse{
  id?:string;
  success?:boolean;
  errors?:any;
}

export class FilloutDelegations {
  filloutDelegation : string[];
}

export class PropertyType {
  propertyType : string[];
}

export class ReportType {
  reportType : string[];
}