import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/public/models/api-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderVariablesService {

  endPoint = "Variables/";
  private headerVariableSubject = new Subject<any>();
  headerVariable$ = this.headerVariableSubject.asObservable();
  constructor(
    private http: HttpClient
  ) { }

  getHeaderVariable(): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(environment.apiUrl  +  'Variable/GetAll-Variables');
  }

  updateHeaderVariable(data: any): Observable<ApiResponse<any>> {
    return this.http.put<ApiResponse<any>>(environment.apiUrl + 'Variable/Update-Variable', data)
    .pipe(
      tap(response => {
        this.headerVariableSubject.next(response);
      })
    );;
  }
}
