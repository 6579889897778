import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser"; 
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";  
import { AuthenticationGuard } from "./helper/Guards/AuthGuard/authentication.guard";
import { RoleGuard } from "./helper/Guards/RoleGuard/role.guard";

const routes: Routes = [
  {
    path:"",
    redirectTo:"public",
    pathMatch:"full"
  },
  {
    path:'public',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path:'consultant',  
    loadChildren: () => import('./consultant/consultant.module').then(m => m.ConsultantModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path:'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate:[AuthenticationGuard,RoleGuard],
  },
  {
    path: '**',
    redirectTo: 'public'
  }
  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
