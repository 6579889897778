import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
 
  constructor(
    private router: Router
  ) { 

  }

  canActivate():boolean{ 
    var userinfo = JSON.parse(localStorage.getItem('userInfo'));
    if(!userinfo) {
      this.router.navigateByUrl("/");  
      return false;
    }
    return true;
  }
  
}
