import { Component, OnInit, ElementRef, ViewChild, HostListener } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { UserServicesService } from "src/app/admin/services/userServices/user-services.service";
import { ApiResponse } from "src/app/public/models/api-response.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HeaderVariablePopupComponent } from "../header-variable-popup/header-variable-popup.component";
import { environment } from "src/environments/environment";

import { DashboardService } from "src/app/admin/services/dashboardServices/dashboard.service";
import { DashboardViewModel } from "src/app/admin/models/opportunity";
import { NotificationService } from "src/app/public/services/notification.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Opportunitiy } from "src/app/consultant/models/opportunitiy";
import { Jobs } from "src/app/consultant/models/job";
import { JobsComponent } from "src/app/admin/pages/jobs/jobs.component";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ThemeService } from "src/app/shared/services/theme/theme.service";


export interface RouteInfo {
  path?: string;
  title: string;
  type: string;
  roleType: string[];
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}

export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

export const ROUTES: RouteInfo[] = [
  {
    path: "/admin/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "ni-shop text-primary",
    roleType: ["Admin","Super Admin"],
    isCollapsed: false
  },
  {
    path: "/admin/setting",
    title: "Settings",
    type: "link",
    icontype: "ni-shop text-primary",
    roleType: ["Admin","Super Admin"],
    isCollapsed: false
  },
  {
    path: "",
    title: "Variables",
    type: "modal",
    icontype: "ni-shop text-primary",
    roleType: ["Admin","Super Admin"],
    isCollapsed: false
  },
  {
    path: "https://duotax.sharepoint.com/:x:/r/sites/TheHub/_layouts/15/Doc.aspx?sourcedoc=%7B05222E8D-BAEA-4AC8-85CC-C2B27CE9E581%7D&file=Mastermind%20Defects.xlsx&action=default&mobileredirect=true&isSPOFile=1&wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&wdExp=TEAMS-TREATMENT&wdhostclicktime=1727153971896&web=1",
    title: "Feedback",
    type: "external",
    icontype: "ni-shop text-primary",
    roleType: ["Admin","Super Admin"],
    isCollapsed: false
  },
  {
    path: "/consultant/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "ni-shop text-primary",
    roleType: ["Consultant"]
  }
];

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})

export class NavbarComponent implements OnInit {
  darkMode$: string;
  public focus;
  public listTitles: any[];
  public location: Location;
  public userName: string;
  public userRole: string;
  public bgColor: string;
  public menuItems: any[];
  sidenavOpen: boolean = true;
  public profile: string;
  public userlog: string;
  public dashboardLink: string;
  public searchJobNumber: string;
  showSearchBox: boolean = false;
  public cuurentUrl: string = '';
  public searchedOpportunity: Opportunitiy;
  public dashboardModal: DashboardViewModel;
  public totalCount: number;
  public loaderShow: boolean = false;
  searchSubject: Subject<string> = new Subject(); // Subject to track search input
  searchValue: string = '';
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private userService: UserServicesService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private notification: NotificationService, private dashboardService: DashboardService,

    private themeService: ThemeService
  ) {
    // this.showSearchBox = false;
    this.location = location;
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator

      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }
      if (event instanceof NavigationError) {
        console.log(event.error);
      }
      if (this.router.url.includes('/admin/dashboard')) {
        this.showSearchBox = false;
        this.searchValue = '';
      } else {
        this.showSearchBox = true;
        this.searchValue = '';
      }
    });
  }

  ngOnInit() {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userName = userInfo.firstName;
    if (userInfo.isSuperAdmin) {
      this.userRole = "Super Admin";
    } else if (userInfo.isAdminInd) {
      this.userRole = "Admin";
    } else {
      this.userRole = "Report Writer";
    }
    // this.bgColor = userInfo.isAdminInd == 1 ? "nav-admin" : "nav-user";
    this.bgColor = environment.name == "Local" ? "nav-dev" : "nav-production";
    this.listTitles = ROUTES.filter(listTitle => listTitle);   
    this.menuItems = ROUTES.filter(menuItem => {
      if(Array.isArray(menuItem.roleType)) {
        return menuItem.roleType.includes(this.userRole)
    }});
    this.profile = userInfo.isAdminInd == 1 || userInfo.isSuperAdmin == 1 ? "/admin/myProfile" : "/consultant/myProfile";
    this.userlog = userInfo.isAdminInd == 1 || userInfo.isSuperAdmin == 1 ? "/admin/userLog" : "/consultant/userLog";  
    this.dashboardLink = userInfo.isAdminInd == 1 || userInfo.isSuperAdmin == 1 ? "/admin" : "/consultant";
    this.darkMode$=userInfo.theme == 1 ? "dark-mode" : "light-mode" ;
    this.setTheme(userInfo.theme);
  }
  ngAfterViewInit() {
    this.dashboardModal = new DashboardViewModel();
    this.searchSubject.pipe(
      debounceTime(1000), // wait for 300ms pause in events
      distinctUntilChanged() // only emit if value is different from previous value
    ).subscribe(debouncedSearchKey => {
      this.getDashboard(1, 15000, debouncedSearchKey);
    });
  }
  checkTheme() {
    if(this.darkMode$ == "dark-mode") {
      document.body.classList.remove("light-mode");
      document.body.classList.add("dark-mode");    
      return true;
    }else {
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
      return false;
    }
  }
  onToggle(event): void {
    if (event.target.checked) {
    this.changeTheme(1);
    this.darkMode$ = "dark-mode";
    document.body.classList.remove("light-mode");
    document.body.classList.add("dark-mode");    
    }else {
      this.changeTheme(0);
      this.darkMode$ = "light-mode";
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    }
  }
  changeTheme(theme:number) {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    var id =Number(userInfo.id);
    this.userService.changeTheme(id,theme).subscribe((result:ApiResponse<any>) => {
      var res =  JSON.parse(localStorage.getItem("userInfo"));
      res.theme = result.data.theme;
      localStorage.setItem("userInfo",JSON.stringify(res));
      this.setTheme(res.theme);
    },(err:Error) => {

    })
  }

  private setTheme(theme: number): void {
    const currentTheme = theme == 1 ? "dark-mode" : "light-mode";
    this.themeService.setTheme(currentTheme);
  }

  logout() {
    localStorage.clear();
    document.body.classList.add("light-mode");
    document.body.classList.remove("dark-mode");
    this.router.navigateByUrl("/");  
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  openVariableModel() {    
    this.modalService.open(HeaderVariablePopupComponent, {
      windowClass: "modal modal-big  fade",
      backdrop: false,
      animation: true,
      size: "lg"
    })
  }

  //set the observabel value to check the user has input value
  onSearch() {
    this.searchSubject.next(this.searchValue);
  }

  clearSearchInput(): void {
    this.searchValue = '';
  }

  onClickInside(): void {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.dropdownMenu.nativeElement.classList.add('show');
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const mouseEvent = event as unknown as MouseEvent;
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(mouseEvent.target) && !this.searchInput.nativeElement.contains(mouseEvent.target)) {
      this.dropdownMenu.nativeElement.classList.remove('show');
    }
  }
  getDashboard(page_Number, page_Count, searchKey: string = null, sortKey: string = null, sortOrder: string = null, selectedDelegation: string = null, selectedPropertyType: string = null, selectedReportType: string = null) {
    this.loaderShow = true;
    this.dashboardService.getDashboard(page_Number, page_Count, searchKey, sortKey, sortOrder, selectedDelegation, selectedPropertyType, selectedReportType).subscribe((result: ApiResponse<DashboardViewModel>) => {
      if (result.succeeded) {
        this.dashboardModal = result.data;
        this.loaderShow = false;

      } else {

        this.notification.ErrorNotification('Error', result.message);
        this.loaderShow = false;
      }
    }, (err: Error) => {
      this.loaderShow = false;
      if (err.message != undefined) {
        this.notification.ErrorNotification('', err.message);
      }
    });
  }

  reload(route: string, queryParams: any): void {
    this.router.navigate([route], { queryParams }).then(() => {
      window.location.reload();
    });
  }
}
