import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themeSubject = new Subject<string>();
  constructor() { }

  setTheme(theme: string) {
    this.themeSubject.next(theme);
  }

}
