import { Injectable } from '@angular/core';
import { ms } from 'date-fns/locale';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    public toastr: ToastrService
  ) { }

  public ErrorNotification(title:string,msg :string) {
    this.toastr.show(
      '<div class="alert-text"</div> <span class="alert-title" data-notify="title">'+title+'</span> <span data-notify="message">'+msg+'</span></div>',
      "",
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-danger alert-notify"
      }
    );
  }

  public SuccessNotification(title:string,msg :string){
    this.toastr.show(
      '<div class="alert-text"</div> <span class="alert-title" data-notify="title">'+title+'</span> <span data-notify="message">'+msg+'</span></div>',
      "",
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-success alert-notify"
      }
    );
  }

  public WarningNotification(title: string, msg: string) {
    this.toastr.show(
      '<div class="alert-text"</div> <span class="alert-title" data-notify="title">' + title + '</span> <span data-notify="message">' + msg + '</span></div>',
      "",
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-warning alert-notify"
      }
    );
  }
  
}
