import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor( 
  ) { 

  }
  canActivate():boolean {
    var userinfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userinfo) {
      if(userinfo.isAdminInd == 1)   
        return true;
    }
    return false;
  }
  
}
