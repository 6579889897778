import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective {

  private readonly allowedPattern = /^[0-9.,]*$/;  

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    let cleanedValue = value.replace(/[^0-9.,]/g, '');  

    const parts = cleanedValue.split('.');

    if (parts.length > 2) {
      cleanedValue = `${parts[0]}.${parts.slice(1).join('')}`;
    }

    // Set the cleaned value back to the input element
    this.renderer.setProperty(this.el.nativeElement, 'value', cleanedValue);
  }
}
