import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/public/models/api-response.model';
import { environment } from 'src/environments/environment'; 
import { DashboardViewModel, FilloutDelegations, Opportunity, PropertyType, ReportType } from '../../models/opportunity';
import { filloutCount } from '../../models/fillout';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  
  getDashboard(page_Number, page_Count, searchKey, sortKey, sortOrder,filloutDelegation,propertyType,reportType) :Observable<ApiResponse<DashboardViewModel>> {
    const query = `?pageNumber=${page_Number}&pageCount=${page_Count}${searchKey ? `&search=${encodeURIComponent(searchKey)}` : ""}${sortKey ? `&sortKey=${sortKey}` : ""}${sortOrder ? `&sortOrder=${sortOrder}` : ""}${filloutDelegation ? `&filloutDelegation=${filloutDelegation}` : ""}${propertyType ? `&propertyType=${propertyType}` : ""}${reportType ? `&reportType=${reportType}` : ""}`;
    return this.http.get<ApiResponse<DashboardViewModel>>(environment.apiUrl + "Admin/Dashboard" + query);
  }
  getAllOpportunities(page_Number,page_Count):Observable<ApiResponse<Opportunity[]>> {
    return this.http.get<ApiResponse<Opportunity[]>>(environment.apiUrl+'Salesforce/GetAll-Opportunity?pageNumber='+page_Number+'&pageCount='+page_Count);
  }
  refreshDashboard(page_Number,page_Count):Observable<ApiResponse<DashboardViewModel>> {
    return this.http.get<ApiResponse<DashboardViewModel>>(environment.apiUrl + 'Admin/RefreshDashboard?pageNumber='+page_Number+'&pageCount='+page_Count);
  }

  getFilloutDelegations() : Observable<ApiResponse<FilloutDelegations>> {
    return this.http.get<ApiResponse<FilloutDelegations>>(environment.apiUrl + 'Admin/GetFilloutDelegationForOpportunity');
  }

  getPropertyType() : Observable<ApiResponse<PropertyType>> {
    return this.http.get<ApiResponse<PropertyType>>(environment.apiUrl + 'Admin/GetPropertyTypeForOpportunity');
  }

  getReportType() : Observable<ApiResponse<ReportType>> {
    return this.http.get<ApiResponse<ReportType>>(environment.apiUrl + 'Admin/GetReportTypeForOpportunity');
  }

  getYesterdayAndTodayFilloutCount() : Observable<ApiResponse<filloutCount>>{              
    return this.http.get<ApiResponse<filloutCount>>(environment.apiUrl + 'Admin/Get_TodayAndYesterdayFilloutCount');
  }
}
