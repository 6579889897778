import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeaderVariablesService } from 'src/app/admin/services/headerVariables/header-variables.service';
import { ApiResponse } from 'src/app/public/models/api-response.model';
import { NotificationService } from 'src/app/public/services/notification.service';

@Component({
  selector: 'app-header-variable-popup',
  templateUrl: './header-variable-popup.component.html',
  styleUrls: ['./header-variable-popup.component.scss']
})
export class HeaderVariablePopupComponent implements OnInit {

  headerVariableForm: FormGroup;

  submitted : boolean = false;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private variableService: HeaderVariablesService,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getHeaderVariables();
  }

  getHeaderVariables() {
    this.spinner.show("variablePopupSpinner");
    this.variableService.getHeaderVariable().subscribe((result: ApiResponse<any>) => {
      if (result) {
        if (result.succeeded) {
          this.setFormData(result.data);
          this.spinner.hide("variablePopupSpinner");
        }
      }
    }, (err: Error) => {
      if (err.message != undefined) {
        this.notification.ErrorNotification('', err.message);
      }
      this.spinner.hide("variablePopupSpinner");
    })
  }

  setFormData(data) {
    data.forEach((item) => {
      if (item.variableKey == "AwningAreaRate") {
        this.headerVariableForm.patchValue({ awing_area: +item.value });
      }
    })
  }

  createForm() {
    const isDebugEnabled =  JSON.parse(localStorage.getItem('debugEnabled'));
    this.headerVariableForm = this.fb.group({
      awing_area: [350, [Validators.required, Validators.pattern(/^\d+$/)]],
      debug: [isDebugEnabled]
    });
  }

  onDebugToggle(event: Event) {
    const target = event.target as HTMLInputElement;
    this.headerVariableForm.patchValue({ debug: target.checked });
  }

  submit() {
    if (this.headerVariableForm.valid) {
      const formData = this.headerVariableForm.value;
      console.log(formData);
      const payload = {
        "variables": [
          {
            "variableKey": "AwningAreaRate",
            "value": formData.awing_area
          }
        ],
        debugEnabled : formData.debug
      }
      this.spinner.show("variablePopupSpinner");
      this.variableService.updateHeaderVariable(payload).subscribe((result: ApiResponse<any>) => {
        if (result) {
          if (result.succeeded) {
            this.spinner.hide("variablePopupSpinner");
            this.notification.SuccessNotification('', "Variable Successfully added");
            localStorage.setItem('debugEnabled', formData.debug);
            this.closeModal();
          }
        }
      }, (err: Error) => {
        if (err.message != undefined) {
          this.notification.ErrorNotification('', err.message);
        }
        this.spinner.hide("variablePopupSpinner");
      })
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
