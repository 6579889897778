import { Router } from '@angular/router';
import { Injectable } from '@angular/core'; 

@Injectable({
  providedIn: 'root'
})
export class Helpers {
  constructor(private router: Router) {

  }

  getitem(key) {
   
    return localStorage.getItem(key);
  }
  setitem(key, val) {

    localStorage.setItem(key,val);
  }
  removeitem(key) {
    localStorage.removeItem(key);
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }
}
